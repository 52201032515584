import axios from 'axios';
import { isatty } from 'tty';

const state = {
  users: [],
  owner:false,
  editedUser:null
};

const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },
  ADD_USER(state, user) {
    state.users.push(user);
  },
  UPDATE_USER(state, updatedUser) {
    const index = state.users.findIndex((b) => b.id === updatedUser.id);
    if (index !== -1) {
      Vue.set(state.users, index, updatedUser)
    }
  },
  SET_STATE_OWNER(state, isOwners){
    console.log("ubo commit",isOwners);
    state.owner = isOwners
  },
  SET_EDITING_USER(state, user){
    state.editedUser = user
  }
};

const actions = {
  async fetchUsers({ commit },{ isOwners = false, isActive = true }) {
    console.log("isOwners",isOwners);
    commit('SET_STATE_OWNER', isOwners);
    try {
      if(!isActive){
        isOwners = true;
      }
      if(!isOwners && !isActive)
      {
        const response = await axios.get('https://tilia-ch.rrota.org/api/User/GetAll');
        const users = response.data;
        commit('SET_USERS', users);
      }
      else {
        const response = await axios.get(`https://tilia-ch.rrota.org/api/User/GetAll?includeOwners=${isOwners}&activeUsers=${isActive}`);
        const users = response.data;
        users.sort((a, b) => b.userTypeId - a.userTypeId);
        commit('SET_USERS', users);

      }
    } catch (error) {
      console.error('fetchUsers action failed with error:', error);
      throw error;
    }
  },
  async createUser({ commit, dispatch }, payload) {
    try {
      const response = await axios.post(
        'https://tilia-ch.rrota.org/api/User/CreateUserViaAdmin',
        payload
      );
      const user = response.data;
      commit('ADD_USER', user);
      dispatch('fetchUsers');
    } catch (error) {
      console.error('createUser action failed with error:', error);
      throw error;
    }
  },
  async updateUser({ commit, dispatch }, payload) {
    try {
      const response = await axios.put(
        'https://tilia-ch.rrota.org/api/User/UpdateUserViaAdmin',
        payload
      );
      const updatedUser = response.data;
      commit('UPDATE_USER', updatedUser);
      dispatch('fetchUsers');
    } catch (error) {
      console.error('updateUser action failed with error:', error);
      throw error;
    }
  },
  editingUser({commit}, payload){
    commit('SET_EDITING_USER', payload);
  }
};

const getters = {
  users: (state) => state.users,
  isOwner:(state) => state.owner,
  getEditedUser:(state) => state.editedUser
};

export default {
  state,
  mutations,
  actions,
  getters
};
