import axios from 'axios';

const state = {
  borderTypes: []
};

const mutations = {
  SET_BORDERTYPES(state, borderTypes) {
    state.borderTypes = borderTypes;
  },
  ADD_BORDERTYPE(state, borderType) {
    state.borderTypes.push(borderType);
  }
};

const actions = {
  async fetchBorderTypes({ commit }) {
    const response = await axios.get('https://tilia-ch.rrota.org/api/BorderType/GetAll');
    const borderTypes = response.data;
    commit('SET_BORDERTYPES', borderTypes);
  },
  async createBorderType({ commit }, payload) {
    const response = await axios.post('https://tilia-ch.rrota.org/api/BorderType/Create', payload);
    const borderType = response.data;
    commit('ADD_BORDERTYPE', borderType);
  }
};

const getters = {
  borderTypes: (state) => state.borderTypes
};

export default {
  state,
  mutations,
  actions,
  getters
};
